.home-wrapper {
    display: flex;
    min-height: 100vh;
    background-color: #f4f6f8;
    font-family: 'Inter', 'Segoe UI', sans-serif;
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 260px;
    height: 100vh;
    background-color: #3e3f49;
    color: #ffffff;
    padding: 24px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.06);
    z-index: 1001;
    transition: left 0.3s ease-in-out;
  }
  
  .main-layout {
    margin-left: 260px;
    width: calc(100% - 260px);
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
    padding: 40px;
    background-color: #ffffff;
    color: #2f2f2f;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .menu-toggle {
    display: none;
    position: fixed;
    top: 16px;
    left: 16px;
    background-color: #6fc3b2;
    color: #fff;
    font-size: 20px;
    padding: 10px 14px;
    border: none;
    border-radius: 8px;
    z-index: 1100;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .menu-toggle:hover {
    background-color: #57b0a1;
  }
  
  @media (max-width: 768px) {
    .menu-toggle {
      display: block;
    }
  
    .sidebar {
      left: -260px;
      background-color: #3e3f49;
    }
  
    .sidebar.open {
      left: 0;
    }
  
    .main-layout {
      margin-left: 0;
      width: 100%;
    }
  
    .content {
      padding: 24px;
    }
  }
  
  /* Overlay for mobile sidebar */
  .overlay {
    display: none;
  }
  
  .sidebar.open ~ .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
  }
  