/* src/index.css */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body, html {
    height: 100%;
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
  }
  