.footer {
    background-color: #3e3f49; /* match left-menu */
    padding: 40px 30px 20px;
    color: #f0f0f0;
    font-family: 'Inter', 'Segoe UI', sans-serif;
    border-top: 1px solid #4c4d56;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto 30px;
    gap: 40px;
  }
  
  .footer-section {
    flex: 1 1 200px;
    min-width: 180px;
  }
  
  .footer-section h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #b9aedc; /* muted lavender for personality */
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #f0f0f0;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: #6fc3b2; /* minty hover accent */
  }
  
  .footer-bottom {
    border-top: 1px solid #4c4d56;
    padding-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #ccccd8;
  }
  
  .social-icons {
    margin-top: 12px;
  }
  
  .social-icons a {
    margin: 0 10px;
    color: #6fc3b2;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #57b0a1;
  }
  
  /* Responsive tweaks */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 30px;
    }
  
    .footer-section {
      min-width: 100%;
    }
  
    .footer-bottom {
      font-size: 13px;
    }
  }
  
  /* Styles for the subscription form */
.subscription-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
  }
  
  .subscription-form input[type="email"] {
    padding: 10px;
    border: 1px solid #4c4d56;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
  }
  
  .subscription-form button {
    padding: 10px;
    background-color: #6fc3b2; /* minty accent */
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .subscription-form button:hover {
    background-color: #57b0a1;
  }
  
  .subscription-message {
    font-size: 14px;
    margin-top: 5px;
    color: #f0f0f0;
  }
  