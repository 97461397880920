/* Updated CSS for Login and Signup Pages */

/* Overall page styles */
.login-page,
.signup-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  background: #f5f6f8; /* neutral background */
  font-family: 'Inter', 'Segoe UI', sans-serif;
}

/* Signup styles */
.signup-container {
  max-width: 500px; /* increased width */
  margin: 0 auto;
  padding: 50px; /* slightly increased padding */
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}

.signup-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #3e3f49; /* neutral dark */
  font-size: 24px;
  font-weight: 600;
}

.signup-container form {
  display: flex;
  flex-direction: column;
}

.signup-container input {
  margin-bottom: 16px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  background-color: #fafafa;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.signup-container input:focus {
  border-color: #6fc3b2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(111, 195, 178, 0.2);
}

.signup-container button {
  padding: 12px;
  background-color: #6fc3b2; /* mint primary */
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.signup-container button:hover {
  background-color: #57b0a1;
}

.signup-container p {
  text-align: center;
  margin-top: 15px;
  color: #555;
  font-size: 14px;
}

/* Login styles */
.login-container {
  max-width: 500px; /* increased width */
  margin: 0 auto;
  padding: 50px; /* slightly increased padding */
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #2f2f2f;
  font-size: 24px;
  font-weight: 600;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container input {
  margin-bottom: 16px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  background-color: #fafafa;
  transition: border 0.3s, box-shadow 0.3s;
}

.login-container input:focus {
  border-color: #6fc3b2;
  box-shadow: 0 0 0 3px rgba(111, 195, 178, 0.2);
  outline: none;
}

.login-container button {
  padding: 12px;
  background-color: #6fc3b2;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #57b0a1;
}

.login-container p {
  text-align: center;
  margin-top: 15px;
  color: #555;
  font-size: 14px;
}

/* Style the button to look like a link */
.forgot-password-button {
    background: none;
    border: none;
    color: #0066cc;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font: inherit;
  }
  
  .forgot-password-button:hover {
    color: #004499;
  }