/* src/components/Home.css */
.dashboard-container {
    display: flex;
    min-height: calc(100vh - 60px);
    overflow-x: hidden;
    position: relative;
}

.sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: white;
    position: fixed;
    height: 100vh;
    z-index: 1000;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.dashboard-main {
    flex: 1;
    padding: 2rem;
    background-color: #f8f9fa;
    margin-left: 250px;
    max-width: calc(100% - 250px);
    box-sizing: border-box;
    min-width: 300px;
}

.welcome-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
}

.welcome-section h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 0.8rem;
    line-height: 1.2;
}

.subtitle {
    font-size: 1.2rem;
    color: #7f8c8d;
    margin-bottom: 2.5rem;
    font-weight: 400;
}

.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
}

.card {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.card:hover {
    transform: translateY(-3px);
}

.card h3 {
    color: #2c3e50;
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    font-weight: 600;
}

.action-buttons {
    display: flex;
    gap: 1.2rem;
    margin-top: 1.5rem;
}

.action-btn {
    padding: 0.8rem 1.8rem;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.action-btn.primary {
    background-color: #6fc3b2;
    color: white;
    box-shadow: 0 2px 4px rgba(111, 195, 178, 0.2);
}

.action-btn.primary:hover {
    background-color: #57b0a1;
    box-shadow: 0 4px 8px rgba(111, 195, 178, 0.3);
}

.action-btn.secondary {
    background-color: #3498db;
    color: white;
    box-shadow: 0 2px 4px rgba(52, 152, 219, 0.2);
}

.action-btn.secondary:hover {
    background-color: #2980b9;
    box-shadow: 0 4px 8px rgba(52, 152, 219, 0.3);
}

.resources-section {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid #eee;
}

.resources-section h3 {
    color: #2c3e50;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    font-weight: 600;
}

.resource-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
}

.resource-card {
    background: white;
    border-radius: 10px;
    padding: 1.8rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    text-decoration: none;
    color: #2c3e50;
    border-left: 4px solid #6fc3b2;
    display: block;
}

.resource-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.resource-card h4 {
    margin-bottom: 0.8rem;
    color: #2c3e50;
    font-size: 1.1rem;
    font-weight: 600;
}

.resource-card p {
    color: #7f8c8d;
    font-size: 0.95rem;
    line-height: 1.4;
}

.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #6fc3b2;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 1.5rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Enhanced Responsive Design */
@media (max-width: 1200px) {
    .dashboard-main {
        padding: 1.8rem;
    }
    
    .dashboard-cards {
        grid-template-columns: 1fr;
    }
    
    .welcome-section h1 {
        font-size: 2.2rem;
    }
}

@media (max-width: 992px) {
    .sidebar {
        width: 220px;
    }
    
    .dashboard-main {
        margin-left: 220px;
        max-width: calc(100% - 220px);
    }
}

@media (max-width: 768px) {
    .dashboard-container {
        flex-direction: column;
    }
    
    .sidebar {
        width: 100%;
        position: relative;
        height: auto;
        padding: 1rem;
    }
    
    .dashboard-main {
        margin-left: 0;
        max-width: 100%;
        padding: 1.5rem;
        min-width: auto;
    }
    
    .welcome-section h1 {
        font-size: 2rem;
    }
    
    .action-buttons {
        flex-direction: column;
        gap: 1rem;
    }
    
    .action-btn {
        width: 100%;
        justify-content: center;
        padding: 1rem;
    }
    
    .resource-cards {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .dashboard-main {
        padding: 1rem;
    }
    
    .welcome-section h1 {
        font-size: 1.8rem;
    }
    
    .subtitle {
        font-size: 1.1rem;
    }
    
    .card {
        padding: 1.5rem;
    }
    
    .resource-card {
        padding: 1.2rem;
    }
}

