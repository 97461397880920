.homepage-container {
    font-family: 'Inter', 'Segoe UI', sans-serif;
    text-align: center;
    padding: 30px;
    background-color: #f9fafb;
    color: #2f2f2f;
  }
  
  .hero {
    background: #3e3f49; /* dark neutral for modern feel */
    color: #ffffff;
    padding: 70px 20px;
    border-radius: 12px;
  }
  
  .hero h1 {
    font-size: 44px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .hero p {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #e0e0e0;
  }
  
  .hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .hero-buttons .btn {
    padding: 14px 32px;
    background: #6fc3b2; /* minty accent */
    color: #ffffff;
    text-decoration: none;
    font-size: 18px;
    border-radius: 8px;
    font-weight: 500;
    transition: background 0.3s ease;
  }
  
  .hero-buttons .btn:hover {
    background: #57b0a1;
  }
  
  .hero-buttons .btn.secondary {
    background: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
  }
  
  .intro {
    margin: 60px auto;
    max-width: 800px;
    text-align: left;
  }
  
  .intro h2 {
    color: #3a3a3a;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 600;
  }
  
  .intro p {
    font-size: 18px;
    line-height: 1.7;
    color: #4a4a4a;
  }
  