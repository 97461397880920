/* src/components/admin/AdminDashboard.css */

.admin-dashboard {
    display: flex;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .admin-sidebar {
    width: 220px;
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 20px;
  }
  
  .admin-sidebar h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .admin-sidebar nav ul {
    list-style: none;
    padding: 0;
  }
  
  .admin-sidebar nav ul li {
    margin-bottom: 15px;
  }
  
  .admin-sidebar nav ul li a {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.2s ease;
  }
  
  .admin-sidebar nav ul li a.active,
  .admin-sidebar nav ul li a:hover {
    color: #1abc9c;
  }
  
  .admin-content {
    flex: 1;
    padding: 20px;
    background-color: #f5f6fa;
  }
  
  .admin-section {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .admin-section h2 {
    margin-bottom: 20px;
  }
  
  /* Table container for responsive design */
  .table-container {
    overflow-x: auto;
    margin-top: 20px;
  }
  
  .admin-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: #fff;
  }
  
  .admin-table th,
  .admin-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .admin-table th {
    background-color: #f2f2f2;
  }
  
  .admin-table tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  .admin-table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Action buttons styling for table */
  .action-btn {
    border: none;
    background: none;
    cursor: pointer;
    margin-right: 5px;
    font-size: 1.1em;
    transition: color 0.2s ease;
  }
  
  .edit-btn {
    color: #1abc9c;
  }
  
  .edit-btn:hover {
    color: #16a085;
  }
  
  .delete-btn {
    color: #e74c3c;
  }
  
  .delete-btn:hover {
    color: #c0392b;
  }
  
  .error {
    color: red;
    font-weight: bold;
  }
  
  /* Admin Form Styling */
  .admin-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .admin-form input[type="email"],
  .admin-form input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .admin-form button {
    padding: 10px 20px;
    background-color: #1abc9c;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .admin-form button:hover {
    background-color: #16a085;
  }
  
  /* Additional Styles */
.admin-subsection {
    margin: 2rem 0;
    padding: 1.5rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .admin-subsection h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .team-member-card {
    background: #f8f9fa;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 6px;
  }
  
  .team-member-card h4 {
    margin: 0 0 0.5rem 0;
    color: #34495e;
  }
  
  .metadata-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .info-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background: #f8f9fa;
    border-radius: 4px;
  }
  
  .info-row label {
    font-weight: 600;
    color: #7f8c8d;
  }
  
  .back-button {
    margin-top: 2rem;
    padding: 0.8rem 1.5rem;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .back-button:hover {
    background: #2980b9;
  }