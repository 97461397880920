.profile-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #f5f6f8;
    font-family: 'Inter', sans-serif;
  }
  
  .profile-container {
    max-width: 500px;
    margin: 40px auto;
    padding: 40px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
    color: #2f2f2f;
  }
  
  .profile-container h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #3e3f49; /* modern neutral */
    font-size: 24px;
    font-weight: 600;
  }
  
  .profile-container form {
    display: flex;
    flex-direction: column;
  }
  
  .profile-container label {
    font-weight: 500;
    margin-bottom: 6px;
    margin-top: 16px;
    color: #444;
  }
  
  .profile-container input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fafafa;
    transition: border 0.3s ease;
  }
  
  .profile-container input:focus {
    border-color: #6fc3b2;
    outline: none;
    box-shadow: 0 0 0 2px rgba(111, 195, 178, 0.2);
  }
  
  .profile-container button {
    margin-top: 25px;
    padding: 12px;
    background-color: #6fc3b2; /* mint button */
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .profile-container button:hover {
    background-color: #57b0a1;
  }
  
  .reset-btn {
    margin-top: 20px;
    background: #b9aedc; /* lavender secondary action */
  }
  
  .reset-btn:hover {
    background: #a395cb;
  }
  
  .profile-message {
    text-align: center;
    margin-top: 20px;
    color: #6fc3b2; /* matches accent palette */
    font-weight: 600;
    font-size: 15px;
  }
  