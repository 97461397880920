.static-page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f5f6f8;
    font-family: 'Inter', 'Segoe UI', sans-serif;
  }
  
  .static-page {
    flex: 1;
    max-width: 900px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    color: #2f2f2f;
    line-height: 1.7;
  }
  
  .static-page h1 {
    color: #3e3f49; /* Neutral dark */
    margin-bottom: 20px;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
  }
  
  .static-page h2 {
    color: #6e92b3; /* muted lavender-blue for subtle vibrancy */
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 500;
  }
  
  .static-page p {
    margin-bottom: 16px;
    color: #4a4a4a;
    font-size: 16px;
  }
  
  .static-page ul {
    list-style: none;
    padding: 0;
  }
  
  .static-page ul li {
    margin-bottom: 10px;
    font-size: 15px;
    color: #444;
  }
  
  .static-page ul li a {
    color: #6fc3b2; /* mint accent for links */
    text-decoration: none;
    transition: color 0.3s ease;
    font-weight: 500;
  }
  
  .static-page ul li a:hover {
    color: #57b0a1;
  }

 /* Contact Form Styles */
.contact-form {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #3e3f49;
    font-size: 15px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 15px;
    color: #4a4a4a;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #6fc3b2;
    box-shadow: 0 0 0 2px rgba(111, 195, 178, 0.1);
}

.form-group textarea {
    resize: vertical;
    min-height: 120px;
}

.submit-button {
    background: #6fc3b2; /* Mint accent color */
    color: white;
    padding: 12px 28px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease;
    width: 100%;
    letter-spacing: 0.5px;
}

.submit-button:hover {
    background: #57b0a1;
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(87, 176, 161, 0.2);
}

.submit-button:disabled {
    background: #c0c0c0;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.status-message {
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
}

.status-message.success {
    background: #e8f6f3;
    color: #1a6458;
    border: 1px solid #6fc3b2;
}

.status-message.error {
    background: #fceded;
    color: #7a2a2f;
    border: 1px solid #f5c6cb;
}