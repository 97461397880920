/* MyApplications.css */
.applications-container {
    max-width: 850px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
    font-family: 'Inter', 'Segoe UI', sans-serif;
}

.applications-container h2 {
    text-align: center;
    color: #3e3f49;
    margin-bottom: 32px;
    font-size: 28px;
    font-weight: 600;
}

.applications-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.application-card {
    padding: 24px;
    border: 2px solid #d0d0d0;
    border-radius: 10px;
    background-color: #ffffff;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.application-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
    border-color: #b0b0b0;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.card-header h3 {
    margin: 0;
    color: #2a2a2a;
    font-size: 20px;
    font-weight: 600;
}

.status-badge {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
}

.status-badge.draft {
    background: #fff3e0;
    color: #b87333;
}

.status-badge.in-progress {
    background: #e8f5e9;
    color: #1a6458;
}

.status-badge.submitted {
    background: #e3f2fd;
    color: #0d47a1;
}

.card-content {
    margin: 15px 0;
}

.founders-list {
    color: #4a4a4a;
    font-size: 15px;
    margin: 10px 0;
    padding: 12px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.update-date {
    color: #757575;
    font-size: 13px;
    margin-top: 12px;
}

.card-actions {
    margin-top: 20px;
    display: flex;
    gap: 12px;
}

.edit-btn, .delete-btn {
    padding: 10px 18px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white !important;
}

.edit-btn {
    background-color: #6fc3b2;
}

.edit-btn:hover {
    background-color: #57b0a1;
}

.delete-btn {
    background-color: #f44336;
}

.delete-btn:hover {
    background-color: #d32f2f;
}

.new-application-btn {
    display: block;
    width: fit-content;
    margin: 40px auto 0;
    padding: 14px 28px;
    background-color: #6fc3b2;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.new-application-btn:hover {
    background-color: #57b0a1;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(111, 195, 178, 0.15);
}

.no-applications {
    text-align: center;
    padding: 40px 0;
}

.no-applications p {
    color: #666;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .applications-container {
        margin: 20px;
        padding: 24px;
    }
    
    .application-card {
        padding: 18px;
    }
    
    .card-actions {
        flex-direction: column;
    }
    
    .card-actions button {
        width: 100%;
    }
}

/* Concept Form Specific Styles */
.concept-form .form-group {
    margin-bottom: 1.5rem;
  }
  
  .concept-form label {
    display: block;
    margin-bottom: 0.8rem;
    color: #3e3f49;
    font-weight: 500;
    font-size: 14px;
  }
  
  .form-input, .form-textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .form-input:focus, .form-textarea:focus {
    border-color: #6fc3b2;
    outline: none;
    box-shadow: 0 0 0 2px rgba(111, 195, 178, 0.2);
  }
  
  .team-members {
    margin: 2rem 0;
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 10px;
  }
  
  .team-members h4 {
    margin: 0 0 1.5rem 0;
    color: #2a2a2a;
  }
  
  .member-input-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .add-member-btn {
    width: 100%;
    background: none;
    border: 2px dashed #d0d0d0;
    color: #6fc3b2;
    padding: 12px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .add-member-btn:hover {
    border-color: #6fc3b2;
    background-color: rgba(111, 195, 178, 0.05);
  }
  
  .form-actions {
    display: flex;
    gap: 15px;
    margin-top: 2rem;
  }
  
  .cancel-btn {
    background-color: #f5f6f8;
    color: #4a4a4a;
    border: 1px solid #e4e4e4;
  }
  
  .cancel-btn:hover {
    background-color: #eceff1;
  }
  
  .submit-btn {
    background-color: #6fc3b2;
    color: white;
  }
  
  .submit-btn:hover {
    background-color: #57b0a1;
  }
  
  .error-message {
    color: #f44336;
    padding: 12px;
    background: #ffecec;
    border-radius: 8px;
    margin: 1rem 0;
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
    .member-input-group {
      grid-template-columns: 1fr;
    }
    
    .form-actions {
      flex-direction: column;
    }
    
    .form-actions button {
      width: 100%;
    }
    
    .team-members {
      padding: 1rem;
    }
  }

  /* Add these styles */
.loading-state {
    text-align: center;
    padding: 40px 0;
    color: #666;
  }
  
  .submissions-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .applications-section h3,
  .concepts-section h3 {
    color: #2a2a2a;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
  }
  
  .type-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
  }
  
  .type-badge.application {
    background: #e8f5e9;
    color: #1a6458;
  }
  
  .type-badge.concept {
    background: #e3f2fd;
    color: #0d47a1;
  }
  
  .status-badge.concept {
    background: #e3f2fd;
    color: #0d47a1;
  }