.application-form-container {
    max-width: 850px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
    font-family: 'Inter', 'Segoe UI', sans-serif;
  }
  
  .application-form-container h2 {
    text-align: center;
    color: #2a2a2a;
    margin-bottom: 25px;
    font-size: 28px;
  }
  
  .form-step h3 {
    color: #444;
    margin-bottom: 15px;
    font-size: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 12px;
    color: #333;
    font-weight: 500;
  }
  
  label input,
  label textarea,
  label select {
    width: 100%;
    padding: 12px;
    margin-top: 6px;
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    font-size: 15px;
    background-color: #fafafa;
    transition: border 0.3s, box-shadow 0.3s;
  }
  
  /* Taller textarea */
  label textarea {
    height: 150px;
    resize: vertical;
  }
  
  label input:focus,
  label textarea:focus,
  label select:focus {
    border-color: #6fc3b2;
    box-shadow: 0 0 0 3px rgba(111, 195, 178, 0.2);
    outline: none;
  }
  
  .founders-section {
    margin-top: 25px;
    padding: 20px;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    background-color: #f8f9fb;
  }
  
  .founder-card {
    margin-bottom: 25px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #ffffff;
  }
  
  .address-section,
  .education-section {
    margin: 20px 0;
    padding: 18px;
    background-color: #f5f6f8;
    border-radius: 8px;
  }
  
  .education-card {
    margin-bottom: 15px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #ffffff;
  }
  
  button {
    padding: 12px 18px;
    margin-top: 12px;
    background-color: #6fc3b2;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #57b0a1;
  }
  
  .form-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .tab {
    padding: 10px 18px;
    background-color: #f0f2f4;
    color: #3d3d3d;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .tab.active {
    background-color: #b9aedc;
    color: #ffffff;
    font-weight: bold;
  }
  
  .tab:hover {
    background-color: #e5e2f1;
  }
  
  .save-exit-btn {
    float: right;
    background-color: #6eada4;
    color: white;
    padding: 10px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .save-exit-btn:hover {
    background-color: #4e948c;
  }
  
  .minor-info {
    background-color: #fdfaf3;
    padding: 15px;
    margin: 15px 0;
    border-left: 5px solid #b8a46f;
    border-radius: 8px;
  }
  