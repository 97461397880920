/* src/components/ApplicationGuide.css */

/* Base container */
.application-form-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
    border-radius: 12px;
  }
  
  /* Guide header */
  .guide-content h2 {
    color: #2c3e50;
    border-bottom: 3px solid #3498db;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  
  /* Section styling */
  .guide-section {
    margin: 3rem 0;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 10px;
    border-left: 4px solid #3498db;
  }
  
  .guide-section h4 {
    color: #2c3e50;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Nested lists */
  .nested-list {
    list-style-type: circle;
    margin-left: 2rem;
  }
  
  .nested-list li {
    margin: 1rem 0;
    padding-left: 1rem;
    border-left: 2px solid #eee;
  }
  
  /* Examples */
  .example {
    background: #fff;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    font-family: 'Courier New', Courier, monospace;
    color: #3498db;
    position: relative;
  }
  
  .example::before {
    content: "Example:";
    display: block;
    font-size: 0.9rem;
    color: #95a5a6;
    margin-bottom: 0.5rem;
  }
  
  /* Pro Tips section */
  .pro-tips {
    background: #fff3e0;
    padding: 2rem;
    border-radius: 10px;
    margin: 2rem 0;
    position: relative;
  }
  
  .pro-tips h4 {
    color: #e67e22;
    margin-bottom: 1rem;
  }
  
  .pro-tips::before {
    content: "💡";
    position: absolute;
    right: 2rem;
    top: -1.5rem;
    font-size: 2rem;
    background: #fff;
    padding: 0.5rem;
    border-radius: 50%;
  }
  
  /* Navigation Links */
  .navigation-links {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 2px solid #eee;
  }
  
  .return-link {
    padding: 0.8rem 1.5rem;
    background: #3498db;
    color: white !important;
    border-radius: 25px;
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .return-link:hover {
    background: #2980b9;
    transform: translateY(-2px);
  }
  
  /* Typography */
  strong {
    color: #3498db;
    font-weight: 600;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .application-form-container {
      margin: 1rem;
      padding: 1rem;
    }
  
    .guide-section {
      padding: 1.5rem;
    }
  
    .navigation-links {
      flex-direction: column;
      gap: 1rem;
    }
  
    .pro-tips::before {
      right: 1rem;
      top: -1rem;
    }
  }
  
  /* Animation */
  @keyframes highlight {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .highlight {
    background: linear-gradient(90deg, #3498db25, #ffffff, #3498db25);
    background-size: 200% 200%;
    animation: highlight 3s ease infinite;
  }