/* src/components/admin/AdminStyles.css */
/* Combined and updated styles */

/* Main admin layout */
.admin-dashboard {
    min-height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f5f6fa;
}

.admin-content {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

/* Tables */
.admin-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    margin: 1rem 0;
}

.admin-table th,
.admin-table td {
    border: 1px solid #e0e0e0;
    padding: 12px;
    text-align: left;
    vertical-align: middle;
}

.admin-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #2c3e50;
}

/* Action buttons - always visible */
.action-buttons {
    display: flex;
    gap: 8px;
}

.view-btn,
.delete-btn {
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    transition: opacity 0.2s;
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.view-btn {
    background-color: #6fc3b2;
    color: white;
    border: none;
}

.delete-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
}

.view-btn:hover,
.delete-btn:hover {
    opacity: 0.9;
}

/* Details Pages - Left aligned layout */
.details-container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 8px;
}

.section h3 {
    margin: 0 0 1rem 0;
    color: #2c3e50;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e0e0e0;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
}

.info-row {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: white;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.info-row label {
    font-weight: 600;
    color: #7f8c8d;
    margin-bottom: 0.5rem;
}

/* Form elements */
.admin-controls {
    margin-top: 2rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 8px;
}

.control-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    max-width: 600px;
}

.back-button {
    padding: 0.8rem 1.5rem;
    background: #6fc3b2;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.back-button:hover {
    background-color: #57b0a1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .admin-content {
        padding: 1rem;
    }
    
    .admin-table {
        font-size: 14px;
    }
    
    .admin-table th,
    .admin-table td {
        padding: 8px;
    }
}