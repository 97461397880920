.left-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #3e3f49;
  color: #ffffff;
  padding: 20px;
  font-family: "Inter", "Segoe UI", sans-serif;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  transition: transform 0.3s ease-in-out;
}

/* Hidden off-screen on mobile */
@media (max-width: 768px) {
  .left-menu {
    transform: translateX(-100%);
  }

  .left-menu.open {
    transform: translateX(0);
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .menu-header,
  .menu-links,
  .logout {
    text-align: center;
  }

  .menu-links li {
    margin-bottom: 12px;
  }
}

.menu-header {
  margin-bottom: 30px;
}

.logo {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #b9aedc;
}

.welcome {
  font-size: 14px;
  color: #ccccd8;
}

.menu-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-links li {
  margin-bottom: 18px;
}

.menu-links a {
  color: #f0f0f0;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s ease;
}

.menu-links a:hover {
  color: #6fc3b2;
}

.logout {
  margin-top: auto;
}

.logout button {
  background: none;
  border: none;
  color: #f0f0f0;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.logout button:hover {
  color: #6fc3b2;
}

.left-menu-layout {
  position: relative;
}

.mobile-header {
  display: none;
  align-items: center;
  justify-content: space-between;
  background-color: #3e3f49;
  color: white;
  padding: 10px 20px;
  font-family: "Inter", "Segoe UI", sans-serif;
}

.hamburger-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive overrides */
@media (max-width: 768px) {
  .mobile-header {
    display: flex;
  }

  .left-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background-color: #3e3f49;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1001;
  }

  .left-menu.open {
    transform: translateX(0);
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .menu-header,
  .menu-links,
  .logout {
    text-align: center;
  }

  .menu-links li {
    margin-bottom: 12px;
  }
}
